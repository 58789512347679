import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { FaBrain, FaHeart } from "react-icons/fa"
import { graphql, useStaticQuery } from "gatsby"

import { motion } from "framer-motion"

const About = () => {
  const data = useStaticQuery(graphql`
    query {
      allFile(
        filter: {
          ext: { regex: "/(jpg)|(png)|(jpeg)/" }
          name: { in: ["about"] }
        }
      ) {
        edges {
          node {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)

  return (
    <AboutContainer>
      <TopLine
        initial={{ y: -250, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        About Us
      </TopLine>
      <Description
        initial={{ x: 250, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        transition={{ duration: 0.5, delay: 0.5 }}
      >
        I want to make programming/coding easy for people.
      </Description>
      <ContentWrapper>
        <ColumnOne>
          <AboutUs>
            <FaBrain
              css={`
                color: #0051ff;
                font-size: 2rem;
                margin-bottom: 1rem;
              `}
            />
            <motion.h3
              initial={{ x: 50, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{ duration: 0.5, delay: 1 }}
            >
              I{" "}
              <FaHeart
                css={`
                  color: red;
                  font-size: 1.4rem;
                `}
              />{" "}
              Sharing My Knowledge
            </motion.h3>
            <motion.p
              initial={{ x: 150, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{ duration: 0.5, delay: 1.5 }}
            >
              <q>
                We share our knowledge about programming/coding so that people
                can make their own websites, apps and more! We start teaching
                from the basics and we go to the most advanced parts of
                programming. Together we will be unstoppable.
              </q>
            </motion.p>
          </AboutUs>
        </ColumnOne>
        <ColumnTwo>
          {data.allFile.edges.map((image, key) => (
            <Images key={key} fluid={image.node.childImageSharp.fluid} />
          ))}
        </ColumnTwo>
      </ContentWrapper>
    </AboutContainer>
  )
}

export default About

const AboutContainer = styled.div`
  width: 100%;
  background: #fcfcfc;
  color: #000;
  padding: 5rem calc((100vw - 1300px) / 2);
  height: 100%;
`

const TopLine = styled(motion.p)`
  color: #077bf1;
  font-size: 1rem;
  padding-left: 2rem;
  margin-bottom: 0.75rem;
`

const Description = styled(motion.p)`
  text-align: start;
  padding-left: 2rem;
  margin-bottom: 4rem;
  font-size: clamp(1.5em, 5vw, 2rem);
  font-weight: bold;
`

const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 0 2rem;

  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`

const ColumnOne = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr;
`

const AboutUs = styled.div`
  padding-top: 1rem;
  padding-right: 2rem;

  h3 {
    margin-bottom: 1rem;
    font-size: 1.5rem;
    font-style: italic;
  }

  p {
    color: #3b3b3b;
  }
`

const ColumnTwo = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  margin-top: 2rem;
  grid-gap: 10px;
`

const Images = styled(Img)`
  border-radius: 10px;
  height: 100%;
`
